import { atom } from "recoil";

export const userState = atom({
  key: "userState",
  default: null,
});

export const orderFilterState = atom({
  key: "orderFilterState",
  default: {},
});

export const productAllFilterState = atom({
  key: "productAllFilterState",
  default: {},
});

export const personalOrderFilterState = atom({
  key: "personalOrderState",
  default: {},
});
