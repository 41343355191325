import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { userState } from "../recoil/atom";
import { API_END_POINT } from "../utils/api";

function useLoad() {
  const setUser = useSetRecoilState(userState);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token || token === "undefined") {
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_position");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_level");

      //로그인페이지 아니면 로그인으로
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }

      return;
    }

    fetch(`${API_END_POINT}/manager/setting/info`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then(function (response) {
      response.json().then(function ({ data }) {
        localStorage.setItem("user_id", data?.user_info?.id);
        localStorage.setItem("user_position", data?.user_info?.position);
        localStorage.setItem("user_name", data?.user_info?.name);
        localStorage.setItem("user_level", data?.user_info?.level);
        localStorage.setItem("branch", data?.user_info?.branch);
        localStorage.setItem("is_use_head", data?.user_info?.is_use_head);
        setUser(data?.user_info || null);
      });
    });
  }, [setUser]);
}

export default useLoad;
