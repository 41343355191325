import React, { useEffect } from "react";
import styled from "@emotion/styled";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactComponent as CloseIcon } from "../../assets/images/close-big-icon.svg";
import Button from "../Button";

function CommonModal({ onClose, children, onCancel, onSubmit, title }) {
  useEffect(() => {
    document.body.style.cssText = `overflow: hidden`;
    return () => {
      document.body.style.cssText = `overflow: "auto"`;
    };
  }, []);

  return (
    <Wrapper>
      <OutsideClickHandler onOutsideClick={onClose}>
        <div className="content">
          <div className="title">{title}</div>
          <div className="close-icon" onClick={onClose}>
            <CloseIcon />
          </div>
          {children}
          <div className="buttons">
            <Button className="round line" onClick={onCancel}>
              취소
            </Button>
            <Button className="round" onClick={onSubmit}>
              등록
            </Button>
          </div>
        </div>
      </OutsideClickHandler>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;

  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  & .content {
    width: 560px;

    background: #ffffff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 24px;

    position: relative;
  }

  & .title {
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
  }

  & .close-icon {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    height: 82px;

    & button {
      height: 46px;
      width: 94px;
    }
  }
`;

export default CommonModal;
