import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { returnReasonModalState } from "../../recoil/atom/modal";
import { InputSection } from "../CommonStyle";
import Input from "../Input";
import CommonModal from "./CommonModal";

function ReturnReasonModal() {
  const [modal, setModal] = useRecoilState(returnReasonModalState);
  const [returnReason, setReturnReason] = useState("");
  const handleClose = () => {
    setModal(null);
  };

  const handleSubmit = () => {
    if (!returnReason) return;
    modal?.onConfirm && modal.onConfirm(returnReason);
    handleClose();
  };

  const handleChange = (e) => {
    setReturnReason(e.target.value);
  };

  const resetReturnReason = () => {
    setReturnReason("");
  };

  useEffect(() => {
    if (modal === null) resetReturnReason();
  }, [modal]);

  if (modal === null) return null;
  return (
    <CommonModal
      onClose={handleClose}
      onCancel={handleClose}
      onSubmit={handleSubmit}
      title="반품처리"
    >
      <InputSection style={{ marginTop: 0 }}>
        <Input
          label="반품 사유"
          styleClass="w_320"
          placeholder="반품 사유"
          value={returnReason}
          onChange={handleChange}
        />
      </InputSection>
    </CommonModal>
  );
}

export default ReturnReasonModal;
