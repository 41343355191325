import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));
export const Login = loadable(() => import("./user/Login"));
export const Check = loadable(() => import("./user/Check"));

// 대시보드
export const Dashboard = loadable(() => import("./dashboard/Dashboard"));
export const NoticeListPage = loadable(() =>
  import("./dashboard/NoticeListPage")
);
export const NoticeDetailPage = loadable(() =>
  import("./dashboard/NoticeDetailPage")
);
export const ReviewListPage = loadable(() =>
  import("./dashboard/ReviewListPage")
);

//브랜드관리
export const Brand = loadable(() => import("./brand/Brand"));

//주문관리
export const Order = loadable(() => import("./order/Order"));

// 오픈마켓
export const OpenMarketRegister = loadable(() =>
  import("./order/OpenMarketRegister")
);
export const OpenMarketEdit = loadable(() => import("./order/OpenMarketEdit"));

//상품관리
export const Product = loadable(() => import("./product/Product"));
export const ProductRegister = loadable(() =>
  import("./product/ProductRegister")
);

//개인결제관리
export const PersonalOrder = loadable(() =>
  import("./personalorder/PersonalOrder")
);

//회원관리
export const Member = loadable(() => import("./member/Member"));
export const CouponRegister = loadable(() => import("./member/CouponRegister"));

//리뉴올/감정
export const PersonalProduct = loadable(() =>
  import("./personalproduct/PersonalProduct")
);

//공지사항/이벤트
export const Event = loadable(() => import("./event/Event"));
export const EventRegister = loadable(() => import("./event/EventRegister"));

//As
export const As = loadable(() => import("./as/As"));
export const AsRegister = loadable(() => import("./as/AsRegister"));

//관리자설정
export const Business = loadable(() => import("./business/Business"));

//관리자 활동기록
export const LogData = loadable(() => import("./logdata/LogData"));

//설정
export const Settings = loadable(() => import("./settings/Settings"));
