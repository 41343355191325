import React from "react";
import { useRecoilValue } from "recoil";
import AlertModal from "../../component/modal/AlertModal";
import ConfirmModal from "../../component/modal/ConfirmModal";
import FeeCalculatorModal from "../../component/modal/FeeCalculatorModal";
import ReturnReasonModal from "../../component/modal/ReturnReasonModal";
import {
  alertModalState,
  confirmModalState,
  feeCalculatorModalState,
  returnReasonModalState,
} from "../../recoil/atom/modal";

function Core() {
  const confirmModal = useRecoilValue(confirmModalState);
  const returnReasonModal = useRecoilValue(returnReasonModalState);
  const alertModal = useRecoilValue(alertModalState);
  const feeCalculatorModal = useRecoilValue(feeCalculatorModalState);
  return (
    <>
      {confirmModal !== null && <ConfirmModal />}
      {returnReasonModal !== null && <ReturnReasonModal />}
      {alertModal !== null && <AlertModal />}
      {feeCalculatorModal !== null && <FeeCalculatorModal />}
    </>
  );
}

export default Core;
