import { useRecoilValue } from "recoil";
import { userState } from "../recoil/atom";
import useAlert from "../hooks/useAlert";

function useUser() {
  const user = useRecoilValue(userState);
  const { accessAuthAlert, editAuthAlert } = useAlert();

  const isDisabledWithoutAllbranch = () => {
    if (!user) return false;
    if (user.level === "5") return false;
    if (user.branch === "all") return false;
    return true;
  };

  const isAuthorizationByLevel = (level, mode) => {
    if (!user) return false;
    if (
      parseInt(user.level) < level &&
      (window.location.pathname !== "/member" ||
        (window.location.search !== "" &&
          window.location.search !== "?page=member" &&
          window.location.pathname === "/member"))
    ) {
      mode === "access" && accessAuthAlert();
      mode === "edit" && editAuthAlert();
      return false;
    }
    return true;
  };

  return { user, isDisabledWithoutAllbranch, isAuthorizationByLevel };
}

export default useUser;
