import { atom } from "recoil";

export const confirmModalState = atom({
  key: "confirmModalState",
  default: null,
});

export const returnReasonModalState = atom({
  key: "returnReasonModalState",
  default: null,
});

export const alertModalState = atom({
  key: "alertModalState",
  default: null,
});

export const feeCalculatorModalState = atom({
  key: "feeCalculatorModalState",
  default: null,
});
