import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";
import { confirmModalState } from "../../recoil/atom/modal";
import OutsideClickHandler from "react-outside-click-handler";

function ConfirmModal() {
  const [modal, setModal] = useRecoilState(confirmModalState);
  const handleClose = () => {
    modal?.onCancel && modal?.onCancel();
    setModal(null);
  };
  const handleConfirm = () => {
    modal?.onConfirm && modal.onConfirm();
    handleClose();
  };

  useEffect(() => {
    document.body.style.cssText = `overflow: hidden`;
    return () => {
      document.body.style.cssText = `overflow: "auto"`;
    };
  }, []);

  if (modal === null) return null;

  return (
    <Wrapper>
      <OutsideClickHandler onOutsideClick={handleClose}>
        <div className="content">
          <h2 className="title">{modal.title}</h2>
          <div className="text">{modal.text}</div>
          <div className="buttons">
            <button className="button close" onClick={handleClose}>
              {modal?.closeText || "취소"}
            </button>
            <button className="button delete" onClick={handleConfirm}>
              {modal?.confirmText || "삭제"}
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;

  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  & .content {
    width: 560px;
    height: 194px;

    background: #ffffff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;

    position: relative;
  }

  & .title {
    height: 32px;

    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: #222222;
  }

  & .text {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 26px */

    color: #555555;
  }

  & .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 16px 0px 0px;
    gap: 8px;
  }

  & .button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: 78px;
    height: 40px;

    border-radius: 4px;

    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  & .close {
    background: #ffffff;
    color: #555555;
  }

  & .delete {
    background: #dd3333;
    color: #ffffff;
  }
`;

export default ConfirmModal;
