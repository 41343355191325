import styled from "@emotion/styled";
import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";

function ModalHeader({ title, onClose }) {
  return (
    <Wrapper>
      <h2>{title}</h2>
      <div className="close-icon-wrapper" onClick={onClose}>
        <CloseIcon />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > h2 {
    font-family: Noto Sans KR;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
  }

  & > .close-icon-wrapper {
    position: absolute;
    right: 0px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export default ModalHeader;
