import React from "react";
import { Redirect } from "react-router";
import useUser from "../hooks/useUser";
import { userState } from "../recoil/atom";
import { useRecoilValue } from "recoil";

function WithAccessAuth({ Component, level, redirectUrl }) {
  const user = useRecoilValue(userState);
  const { isAuthorizationByLevel } = useUser();

  if (user && !isAuthorizationByLevel(level, "access")) {
    return <Redirect to={redirectUrl ? redirectUrl : "/"} />;
  }
  return <Component />;
}

export default WithAccessAuth;
