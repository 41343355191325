import React from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";
import OutsideClickHandler from "react-outside-click-handler";
import { useRecoilState } from "recoil";
import { alertModalState } from "../../recoil/atom/modal";
import Button from "../Button";

function AlertModal() {
  const [modal, setModal] = useRecoilState(alertModalState);

  const handleClose = () => {
    setModal(null);
  };

  const handleConfirm = () => {
    modal?.onConfirm && modal.onConfirm();
    handleClose();
  };

  useEffect(() => {
    if (!document || !document.body) return;
    document.body.style.cssText = `overflow: hidden`;
    return () => {
      if (!document || !document.body) return;
      document.body.style.cssText = `overflow: "auto"`;
    };
  }, []);
  if (modal === null) return null;

  return (
    <Wrapper>
      <OutsideClickHandler onOutsideClick={handleClose}>
        <Content>
          <div className="title">{modal.title}</div>
          <div className="text">{modal.text}</div>
          <div className="buttons">
            <Button type="button" onClick={handleConfirm}>
              {modal?.confirmText || "확인"}
            </Button>
          </div>
        </Content>
      </OutsideClickHandler>
    </Wrapper>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  position: relative;
  width: 560px;
  height: 194px;

  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  & .title {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;

    color: #222222;
  }

  & .text {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;

    color: #555555;
  }

  & .buttons {
    width: 100%;
    height: 56px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 16px 0px 0px;
    gap: 8px;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default AlertModal;
