import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import useSWR from "swr";
import { feeCalculatorModalState } from "../../recoil/atom/modal";
import { getInputValueOfNumberType } from "../../utils";
import Input from "../Input";
import ModalHeader from "./ModalHeader";
import ModalWrapper from "./ModalWrapper";

function FeeCalculatorModal() {
  const modal = useRecoilValue(feeCalculatorModalState);
  const reset = useResetRecoilState(feeCalculatorModalState);

  const [price, setPrice] = useState("");
  const [consignmentPrice, setConsignmentPrice] = useState("");

  const { data } = useSWR("/malladmin/setting/fee");

  const percent = useMemo(() => {
    return {
      a: data?.a_percent || 0,
      b: data?.b_percent || 0,
      y: data?.y_percent || 0,
    };
  }, [data]);

  const handleChangePrice = (isConsignment) => (e) => {
    const value = e.target.value;
    if (isConsignment) {
      setConsignmentPrice(getInputValueOfNumberType(value));
      return;
    }

    setPrice(getInputValueOfNumberType(value));
  };

  if (!modal) return null;

  return (
    <ModalWrapper onClose={reset}>
      <Wrapper>
        <ModalHeader title="위탁 수수료 계산기" onClose={reset} />

        <BoxWrapper>
          <Box>
            <div className="label">판매가</div>
            <div className="w-full">
              <Input
                placeholder="판매가 입력"
                className="w-full"
                value={price}
                onChange={handleChangePrice(false)}
              />
            </div>
          </Box>

          <Box className="mt-16">
            <div className="label">위탁가</div>
            <div className="w-full">
              <Input
                placeholder="위탁가 입력"
                className="w-full"
                value={consignmentPrice}
                onChange={handleChangePrice(true)}
              />
            </div>
          </Box>
        </BoxWrapper>
        <Divide className="mt-24" />

        <BoxWrapper>
          <Box>
            <div className="label">1구간 ({percent.a}%)</div>
            <div className="value">0</div>
          </Box>
          <Box>
            <div className="label">2구간 ({percent.b}%)</div>
            <div className="value">0</div>
          </Box>
          <Box>
            <div className="label">3구간 ({percent.y}%)</div>
            <div className="value">0</div>
          </Box>
          <Box>
            <div className="label">수수료 합계</div>
            <div className="value">0</div>
          </Box>
        </BoxWrapper>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  width: 720px;
  padding: 40px;
  background: #fff;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  & .mt-24 {
    margin-top: 24px;
  }

  & .w-full {
    width: 100%;
  }
`;

const BoxWrapper = styled.div`
  margin-top: 24px;

  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const Box = styled.div`
  display: flex;
  align-items: cetner;

  height: 40px;

  & > .label {
    width: 100px;
    display: flex;
    align-items: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(34, 34, 34, 1);
  }

  & .value {
    display: flex;
    align-items: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(34, 34, 34, 1);
  }
`;

const Divide = styled.div`
  height: 1px;
  background: rgba(238, 238, 238, 1);
`;

export default FeeCalculatorModal;
